<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <v-form
          ref="form"
          lazy-validation
          class="app-form"
          @submit.prevent="onSubmit"
        >
          <v-card class="app-card">
            <v-card-title class="app-card-title">
              <h4 class="text">
                <span>{{ currentRoute === "dashboard-perfis-novo" ? 'Novo' : 'Editar' }} Perfil</span>
              </h4>
            </v-card-title>
            <v-card-text class="app-card-content">
              <v-row>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  :xs="12"
                >
                  <v-text-field
                    v-model="detail.data.name"
                    :rules="[rules.required]"
                    label="Nome Perfil *"
                    placeholder="Ex:. Administrador"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  :xs="12"
                >
                  <v-text-field
                    v-model="detail.data.key"
                    :rules="[rules.required]"
                    label="Key *"
                    placeholder="Ex:. ADMIN"
                    outlined
                    dense
                    :disabled="$route.name === 'dashboard-perfis-editar'"
                  />
                </v-col>
              </v-row>
              <v-form
                ref="formFuncionalidade"
                lazy-validation
                class="app-form"
              >
                <v-row>
                  <v-col
                    :lg="12"
                    :md="12"
                    :sm="12"
                    class="pt-0 pb-0"
                  >
                    <h4 class="title">
                      Funcionalidades
                    </h4>
                  </v-col>
                  <v-col
                    :lg="10"
                    :md="10"
                    :sm="10"
                    class="pt-3 pb-0"
                  >
                    <v-autocomplete
                      v-model="formFuncionalidade.funcionalidadeObj"
                      label="Tipos de Documentos"
                      :items="funcionalidades"
                      item-text="name"
                      return-object
                      outlined
                      dense
                      :rules="[rules.required]"
                      :loading="loadingFuncionalidades"
                    />
                  </v-col>
                  <v-col
                    :lg="2"
                    :md="2"
                    :sm="2"
                    class="pt-3 pb-0"
                  >
                    <v-btn
                      depressed
                      block
                      color="primary"
                      type="button"
                      @click.prevent="addFuncionalidade"
                    >
                      <v-icon>add</v-icon>Adicionar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider />
              <v-list two-line>
                <v-list-item-group>
                  <template v-for="(item, index) in detail.data.profilesFunctionalities">
                    <v-list-item :key="index+detail.data.profilesFunctionalities.length">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.functionality.name" />
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            color="red lighten-1"
                            @click.prevent="deleteFuncionalidade(item)"
                          >
                            delete
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    <v-divider
                      v-if="index < detail.data.profilesFunctionalities.length - 1"
                      :key="index"
                    />
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-divider />
            <v-card-actions class="app-card-actions">
              <span class="app-form__warning">
                Atenção: Campos que possuem o
                <em>*</em> são obrigatórios
              </span>
              <v-spacer />
              <v-btn
                depressed
                outlined
                color="error"
                @click="$router.back()"
              >
                Voltar
              </v-btn>
              <v-btn
                depressed
                color="success"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
            <section
              v-if="detail.isLoading"
              class="loading"
            >
              <v-progress-circular
                indeterminate
                color="success"
              />
              <span>Enviando informações ...</span>
            </section>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import FuncionalidadeService from '@/services/funcionalidades.service';

  export default {
    data: () => ({
      currentRoute: null,
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido'
      },
      formFuncionalidade: {
        funcionalidadeObj: null
      },
      funcionalidades: [],
      loadingFuncionalidades: false
    }),
    computed: {
      ...mapState('perfil', ['detail'])
    },
    mounted() {
      this.iniData();
    },
    destroyed() {
      this.clearResource();
    },
    methods: {
      iniData() {
        const {
          params: { id },
          name
        } = this.$route;
        this.currentRoute = name;
        this.loadFuncionalidades();
        if (name === 'dashboard-perfis-editar') {
          this.getResource(id);
        }
      },
      onSubmit() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          if (this.detail.data.id) {
            this.updateResource(this.detail.data);
          } else {
            this.createResource(this.detail.data);
          }
        }
      },
      loadFuncionalidades() {
        this.loadingFuncionalidades = true;
        const query = {
          input: {
            name: null,
            key: null
          },
          inputPage: {
            page: 1,
            size: 1000,
            order: 'id',
            descingOrder: false
          }
        };
        FuncionalidadeService.page(query).subscribe(
          ({ items }) => {
            this.loadingFuncionalidades = false;
            this.funcionalidades = items;
          },
          err => {
            this.loadingFuncionalidades = false;
          }
        );
      },
      addFuncionalidade() {
        const isValid = this.$refs.formFuncionalidade.validate();

        if (isValid) {
          const { funcionalidadeObj } = this.formFuncionalidade;
          const func = {
            functionality: funcionalidadeObj
          };

          this.detail.data.profilesFunctionalities = [
            ...this.detail.data.profilesFunctionalities,
            func
          ];
        }
      },
      deleteFuncionalidade(item) {
        const index = this.detail.data.profilesFunctionalities.indexOf(item);
        this.detail.data.profilesFunctionalities.splice(index, 1);
      },
      ...mapActions('perfil', { getResource: 'SHOW' }),
      ...mapActions('perfil', { clearResource: 'CLEAR_DETAIL' }),
      ...mapActions('perfil', { createResource: 'CREATE' }),
      ...mapActions('perfil', { updateResource: 'UPDATE' })
    },
  };
</script>

<style lang="scss" scoped>
.title {
  margin: 0px;
  font-size: 15px !important;
  font-weight: bold !important;
}
</style>

